import React, { FC, useCallback, useContext, useMemo } from 'react';
import { ProductPackagesFetcher } from '../../fetchers/ProductPackagesFetcher';
import { ProductsFetcher } from '../../fetchers/ProductsFetcher';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { UseFormReturn } from 'react-hook-form';
import { TextInputController } from '../InputControllers/TextInputController';
import { SelectController } from '../InputControllers/SelectController';
import { TransferListWithAmount } from '../InputControllers/TransferListWithAmount';
import { useAutoFetch } from '../../contexts/Fetcher';
import { CheckboxController } from '../InputControllers/CheckboxController';
import { useScrollToError } from '../../hooks/useScrollToError';
import { useTranslation } from 'react-i18next';
import styled from '@mui/styles/styled';
import { Chip } from '@mui/material';
import { compareBool, compareString, sortItems } from '../../helpers/compare';
import { ProductPlansFetcher } from '../../fetchers/ProductPlansFetcher';

const planTypeItems = [
  {
    value: 'subscription',
    label: 'Subscription',
  },
  {
    value: 'perpetual',
    label: 'Perpetual',
  },
];

interface IPackageFormValue {
  packageId: number;
  amount: number;
}

interface ICustomerPackageDefaultProperties {
  [key: string]: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    transferList: {
      height: 300,
    },
  })
);

export interface IProductPlanFormPayload {
  name: string;
  type: 'perpetual' | 'subscription';
  price: string;
  duration: string;
  productCode?: string;
  packages: IPackageFormValue[];
  productId: number;
  watermark: boolean;
  customerPackageDefaultProperties: ICustomerPackageDefaultProperties;
}

interface IProps {
  form: UseFormReturn;
  onSubmit: (data: IProductPlanFormPayload) => void;
  editPlanId?: number;
}

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

export const ProductPlanForm: FC<IProps> = ({ form, onSubmit, editPlanId }) => {
  const { data: packagesData } = useContext(ProductPackagesFetcher.Context);
  const { data: productsData } = useContext(ProductsFetcher.Context);
  const { data: plansData } = useContext(ProductPlansFetcher.Context);
  const isEdit = !!editPlanId;

  useAutoFetch(ProductPackagesFetcher);
  useAutoFetch(ProductsFetcher);

  const { formRef } = useScrollToError(form);

  const styles = useStyles();
  const { t } = useTranslation();

  const { watch } = form;

  const duration = watch('duration');

  const productItems = useMemo(() => {
    if (!productsData) return [];
    return productsData.products.map((product) => ({
      value: product.id,
      label: product.name,
    }));
  }, [productsData]);

  const sortedPackages = useMemo(() => {
    if (!packagesData) return [];

    return sortItems(
      packagesData.packages,
      [(pack) => pack.isAddon, (pack) => pack.name],
      [compareBool, compareString]
    );
  }, [packagesData?.packages]);

  const validateProductCode = useCallback(
    (productCode: string) => {
      if (
        plansData?.plans.some((plan) => {
          return editPlanId !== plan.id && (plan.productCode ? productCode === plan.productCode : false);
        })
      ) {
        return t('adminProducts.plans.form.validation.duplicatedProductCode');
      }
      return true;
    },
    [plansData, editPlanId]
  );

  if (!packagesData || !productsData) return null;
  return (
    <Grid container spacing={1} component={'form'} onSubmit={form.handleSubmit(onSubmit)} ref={formRef}>
      <Grid item xs={6} md={3}>
        <TextInputController
          control={form.control}
          errors={form.formState.errors}
          rules={{ required: true }}
          label={t('adminProducts.plans.form.name')}
          name="name"
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <SelectController
          control={form.control}
          errors={form.formState.errors}
          items={planTypeItems}
          label={t('adminProducts.plans.form.type')}
          name="type"
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextInputController
          control={form.control}
          errors={form.formState.errors}
          label={t('adminProducts.plans.form.price')}
          inputType="number"
          name={'price'}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            inputProps: {
              min: 0,
            },
          }}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextInputController
          control={form.control}
          errors={form.formState.errors}
          label={t('adminProducts.plans.form.duration')}
          name={'duration'}
          inputType="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">{t('adminProducts.plans.form.durationDays')}</InputAdornment>,
            inputProps: {
              min: 0,
            },
          }}
        />

        {parseFloat(duration) < 1 ? (
          <StyledFormHelperText>
            {t('adminProducts.plans.form.durationStripeDiscrepancy', { count: 1 })}
          </StyledFormHelperText>
        ) : parseFloat(duration) > 365 ? (
          <StyledFormHelperText>
            {t('adminProducts.plans.form.durationStripeDiscrepancy', { count: 365 })}
          </StyledFormHelperText>
        ) : duration && parseFloat(duration) !== parseInt(duration) ? (
          <StyledFormHelperText>
            {t('adminProducts.plans.form.durationStripeDiscrepancy', { count: Math.round(parseFloat(duration)) })}
          </StyledFormHelperText>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <TransferListWithAmount
          form={form}
          name="packages"
          label={t('adminProducts.plans.form.packages')}
          data={sortedPackages}
          compare={(pack, value: IPackageFormValue) => pack.id === value.packageId}
          getItemKey={(pack) => pack.id}
          getItemLabel={(pack) => (
            <span>
              {pack.isAddon ? <Chip color="info" label={t('assignLicenses.addonChip')} /> : null} {pack.name}
            </span>
          )}
          getValueAmount={(value) => value.amount}
          valueGenerator={(pack, amount) => ({ packageId: pack.id, amount })}
          classNames={{ itemsList: styles.transferList }}
        />
      </Grid>
      {!isEdit ? (
        <Grid item xs={12}>
          <SelectController
            control={form.control}
            errors={form.formState.errors}
            items={productItems}
            rules={{ required: true }}
            label={t('adminProducts.plans.form.product')}
            name="productId"
            disabled={isEdit}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <TextInputController
          control={form.control}
          errors={form.formState.errors}
          rules={{
            required: false,
            validate: validateProductCode,
          }}
          label={t('adminProducts.plans.form.productCode')}
          name="productCode"
        />
      </Grid>
      <Grid item xs={12}>
        <CheckboxController
          control={form.control}
          errors={form.formState.errors}
          label={t('adminProducts.plans.form.watermark.label')}
          name="watermark"
        />
        <Typography variant="caption" display="block">
          {t('adminProducts.plans.form.watermark.helper')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CheckboxController
          control={form.control}
          errors={form.formState.errors}
          defaultValue={true}
          label={t('adminProducts.plans.form.notification_reneval_90_days.label')}
          name="customerPackageDefaultProperties.notification_reneval_90_days"
        />
      </Grid>
      <Grid item xs={12}>
        <CheckboxController
          control={form.control}
          errors={form.formState.errors}
          defaultValue={true}
          label={t('adminProducts.plans.form.notification_reneval_60_days.label')}
          name="customerPackageDefaultProperties.notification_reneval_60_days"
        />
      </Grid>
      <Grid item xs={12}>
        <CheckboxController
          control={form.control}
          errors={form.formState.errors}
          defaultValue={true}
          label={t('adminProducts.plans.form.notification_reneval_30_days.label')}
          name="customerPackageDefaultProperties.notification_reneval_30_days"
        />
      </Grid>
      <Grid item xs={12}>
        <CheckboxController
          control={form.control}
          errors={form.formState.errors}
          defaultValue={false}
          label={t('adminProducts.plans.form.notification_expiring_30_days.label')}
          name="customerPackageDefaultProperties.notification_expiring_30_days"
        />
      </Grid>
    </Grid>
  );
};
