import { useApi } from './useApi';
import { useCallback } from 'react';
import { IPaginatedContent } from '../models/PaginatedContent';
import { ICronJob } from '../models/CronJob';
import { ScheduledJobsEndpoints } from './api/endpoints/scheduledJobs';
import { ApiRequest, useEndpoint, useSimpleEndpoint } from './api/apiEndpoint';
import { IServerSidePaginatedFetcherParams } from '../contexts/Fetcher';

export type PendingJobType =
  | 'customerNewProduct'
  | 'deactivateOfflineLicense'
  | 'customerRenewProduct'
  | 'licenseExpiringSoon'
  | 'customerSuspendProduct';

interface ScheduleAddProductRequest {
  customerId: string;
  productId: string;
  planId: string;
  amount: string;
  sendNotifications: string;
  'properties.notification_reneval_90_days': string;
  'properties.notification_reneval_60_days': string;
  'properties.notification_reneval_30_days': string;
  'properties.notification_expiring_30_days': string;
}

interface ScheduleRenewProductRequest {
  customerProductId: string;
  customerId: string;
  sendNotifications: string;
}

interface ScheduleSuspendProductRequest {
  customerProductId: string;
  customerId: string;
}

export const useScheduledJobsApi = () => {
  const { del, get, post } = useApi();

  const scheduleOperation = useEndpoint(
    (post, scheduleTime: number, operation: PendingJobType, request: any) => post({ operation, request, scheduleTime }),
    ScheduledJobsEndpoints.CronJobs,
    post as ApiRequest<typeof post, unknown>
  );

  const scheduleAddProduct = useCallback(
    async (time: number, request: ScheduleAddProductRequest) => {
      return await scheduleOperation([], time, 'customerNewProduct', request);
    },
    [scheduleOperation]
  );

  const scheduleRenewProduct = useCallback(
    async (time: number, request: ScheduleRenewProductRequest) => {
      return await scheduleOperation([], time, 'customerRenewProduct', request);
    },
    [scheduleOperation]
  );

  const scheduleSuspendProduct = useCallback(
    async (time: number, request: ScheduleSuspendProductRequest) => {
      return await scheduleOperation([], time, 'customerSuspendProduct', request);
    },
    [scheduleOperation]
  );

  const getScheduledJobs = useEndpoint(
    (get, type: PendingJobType, { page, per, sort }: IServerSidePaginatedFetcherParams) => {
      const filter = { filterField: 'operation', filterOp: 'eq', filterValue: type };
      return get({ params: { page, per, ...sort, ...filter } });
    },
    ScheduledJobsEndpoints.CronJobs,
    get as ApiRequest<typeof get, { cronJobs: IPaginatedContent<ICronJob> }>
  );

  const deleteScheduledJob = useSimpleEndpoint(ScheduledJobsEndpoints.CronJob, del);

  return {
    deleteScheduledJob,
    getScheduledJobs,
    scheduleAddProduct,
    scheduleRenewProduct,
    scheduleSuspendProduct,
  };
};
